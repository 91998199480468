<template>
	<div class="col-12 col-lg-8 col-xl-8">

		<div class="row mb-4">
			<div class="col"></div>
			<div class="col-12 col-lg-8">
				<label class="mb-0">
	                {{ $t('pax.select_country') }}
	            </label>
	            <e-select
					track-by="id"
					label="name"
					:options="countries_iso_array"
					:searchable="true"
					:allow-empty="false"
					:show-labels="false"
					:search="true"
					:placeholder="$t('global.search_country')"
					:deselectLabel="$t('global.selected_label')"
					:selectedLabel="$t('global.press_enter_to_select')"
					:selectLabel="$t('global.search_country')"
					v-model="country"
				>
		            <template slot="singleLabel" slot-scope="{ option }">
		                <img v-if="option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${option.iso}.png`)">
		                <span class="ml-2">{{ option.name }}</span>
		            </template>
		            <template slot="option" slot-scope="props">
		                <img v-if="props.option.iso" :src="require(`GroomyRoot/assets/img/flag-iso/${props.option.iso}.png`)">
		                <span class="ml-2">{{ props.option.name }}</span>
		            </template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>
			<div class="col"></div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="intro">
					<div v-html="text_intro"></div>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-12">
				<button @click="setCountry" class="btn btn-primary btn-country d-block">{{ $t('global.suivant') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
			</div>
		</div>
				<div class="row">
			<div class="col-12 text-center">
				<img id="logo-pax" class=" my-4" :src="require(`GroomyRoot/assets/img/pax.svg`)">
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Config from '@/mixins/Config.js'
	import Countries from '@/assets/lang/countries/countries'

	export default {
		name: 'ChoixCountry',
		mixins: [Config],
		props: {
			pax_express: {
				type: Boolean,
				default: () => false
			}
		},
		data () {
			return {
				countries: [],
				countries_iso: [],
				countries_iso_array: [],
				country: null,
				text_intro: ''
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
				this.text_intro = this.getConfig('translations')['pax_introduction'][Vue.i18n.locale()]
				if(this.pax_express) {
					this.text_intro = this.getConfig('translations')['pax_express'][Vue.i18n.locale()]
				}
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3
				const countries_iso_2 = Countries[Vue.i18n.locale()].iso

				let countries_values = Object.values(this.countries)
				let countries_iso_values = Object.values(this.countries_iso)

				let temp = []
				for (let i = 0; i < countries_values.length; i++) {
					temp.push(countries_values[i])
				}

				let countries_iso_array_temp = []
				let countries_keys = Object.keys(this.countries)
				let countries_formatted = Object.fromEntries(countries_keys.map((_, i) => [countries_keys[i], temp[i]]))

				for (let [key, value] of Object.entries(countries_formatted)){
					countries_iso_array_temp.push({
						id: key,
						name: value,
						iso: this.countries_iso[key],
						iso_2: countries_iso_2[key]
					})
				}
				this.countries_iso_array = countries_iso_array_temp.sort((a,b) => {
					let x = this.accentsTidy(a.name.toLowerCase());
				    let y = this.accentsTidy(b.name.toLowerCase());
				    return x < y ? -1 : x > y ? 1 : 0;
				});
				let user_lang = Vue.i18n.locale().toUpperCase()
				if(user_lang == 'EN') {
					user_lang = 'GB'
				}

				this.country = countries_iso_array_temp.find(country => country.iso_2 == user_lang)
			},

			setCountry() {
				this.setConfig('country', this.country)
				this.$emit('country_choosed')
			}
		}
	}

</script>